
import moment from 'moment'; 
import statics from '../common/statics';   
import osHelper from '../common/osHelper';


function formatEventDate(date){
    let diff = moment(Date.now()).startOf('day').diff(moment(date).startOf('day'), 'days');

    //let diff = moment().diff(date, 'days');
    if (diff < 1){ return 'Bugün ' + moment(date).format('HH:mm');}
    else if (diff<2) {return 'Dün ' + moment(date).format('HH:mm');}
    else {return moment(date).format('DD.MM.YYYY HH:mm'); }
}

function redirectToNetworkErrorPage(){
    
    // handle generic network error  
    if (osHelper.isCordova){ 
        window.open("index.html#" + statics.NavigationPaths.GenericNetworkError,'_self');
    }
    else{ 
        window.location.replace(statics.NavigationPaths.GenericNetworkError); 
    }
}

function redirectToHomePage(){
    
    // handle generic network error  
    if (osHelper.isCordova){ 
        window.open("index.html#" + statics.NavigationPaths.Home,'_self');
    }
    else{ 
        window.location.replace(statics.NavigationPaths.Home); 
    }
}

function redirectToRoot(){
     // navigate to login page 
     if (osHelper.isCordova){ 
        window.open("index.html",'_self');
    }
    else{ 
        window.location.replace(statics.NavigationPaths.Root); 
    }
}

export {
    formatEventDate,
    redirectToNetworkErrorPage,
    redirectToRoot,
    redirectToHomePage
}