import React from "react";  
import { Button } from 'react-bootstrap'; 
import memoryCache from './common/memoryCache';
import statics from './common/statics'; 
import osHelper from './common/osHelper'; 
import secureStorage from './common/secureStorage'; 
import { authenticate, rememberUserCredentials, forgetUserCredentials, APIVersionCompatible} from './services/AuthenticationService';  
import cookie from 'js-cookie';
import $ from 'jquery';
import {PostLoginLog} from './services/LoggingService';

class Login extends React.Component {
    state = {
        deviceInfo: null,
        savedCredentials: { username:'', password:'', rememberMeChecked:false},
        loginErrorCode: "" 
    };

	onDeviceReady = () => {
		secureStorage.get(statics.Cookie.Key, (val) => this.getCredentialsFromSecureStorage(val)); 
		/*eslint-disable */
        let deviceInfo = { 
            model:device.model, 
            osname: device.platform, 
            manufacturer: device.manufacturer, 
            deviceid: device.uuid};
        /*eslint-enable */
		this.setState({deviceInfo : deviceInfo});
    } 

    
	getCredentialsFromSecureStorage = (val) => {
		
		var parsedCookieObj = {
			rememberMeChecked : (val != null)
		};

		if (parsedCookieObj.rememberMeChecked){ 			
			var parsedValue = JSON.parse(val) 
			parsedCookieObj.username = parsedValue.username;
			parsedCookieObj.password = parsedValue.password;
		}

		this.setState({savedCredentials: parsedCookieObj});
	}

	getCredentialsFromCookie = () => {
		var credentialsInCookie = cookie.get(statics.Cookie.Key);		
        
        var parsedCredentials = {
			rememberMeChecked : (credentialsInCookie != null)
		};

		if (parsedCredentials.rememberMeChecked){
			let jsonObject = JSON.parse(credentialsInCookie);
			parsedCredentials.username = jsonObject.username;
			parsedCredentials.password = jsonObject.password;
        }
        
		return parsedCredentials;
	}
      
    componentDidMount(){ 
        // set login error code as empty initially
        this.setState({loginErrorCode:""});

        // set cordova device ready function
        document.addEventListener("deviceready", this.onDeviceReady, false);
		if(osHelper.isWeb()){ 
			var savedCredentials = this.getCredentialsFromCookie();
			this.setState({savedCredentials: savedCredentials});
        }
    }
   
    // validates credentials entered into form
    validateLoginForm = (event) =>{
        // Fetch form to apply custom Bootstrap validation
        var form = $("#js-login");
        let validationResult = true;
        if (form[0].checkValidity() === false)
        {
            event.preventDefault();
            event.stopPropagation();
            validationResult = false;
        }

        form.addClass('was-validated');
        return validationResult;
    }

    // continue login in if api version check is successfull
    getAPIVersionSuccessfull = (compatible) => {
        if (compatible){    
            let username = this.refs.username.value;
            let password = this.refs.password.value;
            let rememberme = this.refs.rememberme.value;

            let credentials = { username:username, password:password, rememberme:rememberme };
            let handlers = {
                successFunction: this.loginSuccessfull,
                failFunction: this.loginFailed 
            };

            // autenticate and request for token
            if (!authenticate(credentials, handlers)){
                // inform user that login failed
                this.setState({loginErrorCode:"LoginFailed"}); 
            } 
        } else {
            this.setState({loginErrorCode:"APIVersionMismatch"});
        }
    }

    // take action upon api version failure. 
    getAPIVersionFailed = () => {
        // log error
        // todo: redirect user to newer version or inform as so.
    }

    // initiates login process
    initLogin =(event)=>{
        if (!this.validateLoginForm(event)) return;
        
        let handlers = {
            successFunction: this.getAPIVersionSuccessfull,
            failFunction: this.getAPIVersionFailed 
        };

        APIVersionCompatible(handlers); 
    }

    // method to handle login successfull event
    loginSuccessfull = (result) =>{
        const credentials = result.credentials;
        const token = result.token;

         // set login cookie
         if (credentials.rememberme) 
            rememberUserCredentials(credentials)
        else 
            forgetUserCredentials(); 
            
        // Keep token in memory cache 
        memoryCache.set('token', token);

        // initiate mobile functionalities (e.g. crash tracking, push notifications) if available        
        if (osHelper.isCordova()){
            this.initMobileFunctions(result.account_id, result.username, result.user_id);
        }

        this.props.history.push(statics.NavigationPaths.Home);
    }

    // method to handle login failed event
    loginFailed= () =>{
        // TODO: handle
        console.log("login failed.");
    } 

    // initiate cordova related operations and functions
    initMobileFunctions = (accountid, username, userid) => { 
        window.fabric.Crashlytics.setUserIdentifier(accountid);
        window.fabric.Crashlytics.setUserName(username);  
        const deviceInfo = this.state.deviceInfo;
        window.plugins.OneSignal.getIds((ids) => {
            // get player id
            let playerId = JSON.stringify(ids['userId']);
            // save player id
            memoryCache.set('playerId', playerId); 
            // save device info
            memoryCache.set('deviceInfo', deviceInfo);
            // push it to server 
            const requestBody = { DeviceId: playerId, DeviceInformation: JSON.stringify(deviceInfo), UserId: userid };
            PostLoginLog(requestBody);
        }); 
    }

    onUserNameChanged = (event) => {
        let c = this.state.savedCredentials;
        c.username = event.target.value;
        this.setState({ savedCredentials: c });
    };

    render(){ 
    
        var style = {
            background: "url(../pimg/svg/pattern-1.svg) no-repeat center bottom fixed",
            backgroundSize: "cover"
        };

        return (  
                <div className="page-content-wrapper bg-transparent m-0">
                    <div className="height-10 w-100 shadow-lg px-4 bg-brand-gradient">
                        <div className="d-flex align-items-center container p-0">
                            <div className="page-logo width-mobile-auto m-0 align-items-center justify-content-center p-0 bg-transparent bg-img-none shadow-0 height-9">
                                <a href=" #" className="page-logo-link press-scale-down d-flex align-items-center">
                                    <img src="img/logo.png" alt="SmartAdmin WebApp" aria-roledescription="logo" />
                                    <span className="page-logo-text mr-1">İstanbul Güvenlik Merkezi</span>
                                </a>
                            </div> 
                        </div>
                    </div>
                    <div className="flex-1" style={style}>
                        <div className="container py-4 py-lg-5 my-lg-5 px-4 px-sm-0">
                            <div className="row">
                                <div className="col-sm-12 col-md-6 col-lg-5 col-xl-4 ml-auto">
                                    <h1 className="text-white fw-300 mb-3 d-sm-block d-md-none">
                                        Güvenli Giriş
                                    </h1>
                                    <div className="card p-4 rounded-plus bg-faded">
                                        <form id="js-login" noValidate="">
                                            <div className="form-group">
                                                <label className="form-label" htmlFor="username">Kullanıcı</label>
                                                <input type="text" id="username" ref="username" className="form-control form-control-lg" placeholder="kullanıcı adınız" onChange={this.onUserNameChanged} value={this.state.savedCredentials.username} required />
                                                <div className="invalid-feedback">Lütfen boş geçmeyiniz.</div>
                                                <div className="help-block">Kullanı adınızı belirtiniz</div>
                                            </div>
                                            <div className="form-group">
                                                <label className="form-label" htmlFor="password">Parola</label>
                                                <input type="password" id="password" ref="password"  className="form-control form-control-lg" placeholder="parola"  defaultValue={this.state.savedCredentials.password} required />
                                                <div className="invalid-feedback">Lütfen boş geçmeyiniz.</div>
                                                <div className="help-block">Parolanız</div>
                                            </div>
                                            <div className="form-group text-left">
                                                <div className="custom-control custom-checkbox">
                                                    <input type="checkbox"  ref="rememberme" className="custom-control-input" id="rememberme"  checked={this.state.savedCredentials.rememberMeChecked} 
                                                    onChange={() => {let savedCredentials = this.state.savedCredentials;
                                                            savedCredentials.rememberMeChecked = !savedCredentials.rememberMeChecked;
                                                            this.setState ({ savedCredentials: savedCredentials }); }} />
                                                    <label className="custom-control-label" htmlFor="rememberme"> Kullanıcı adımı hatırla</label>
                                                </div>
                                            </div>
                                            <LoginError errorCode={this.state.loginErrorCode} />
                                            <div className="row no-gutters"> 
                                                <div className="col-lg-6 pl-lg-1 my-2"> 
                                                    <Button id="js-login-btn" onClick={this.initLogin} className={"btn btn-danger btn-block btn-lg"}>Giriş</Button>  
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="position-absolute pos-bottom pos-left pos-right p-3 text-center text-white">
                                2020 © İstanbul Güvenlik Merkezi
                            </div>
                        </div>
                    </div>
                </div> 
        );
    }
}

export default Login;
 
export class LoginError extends React.Component { 

    createErrorMessage(errorCode){ 
        if (errorCode === "LoginFailed")
            return (<i><strong>Giriş başarısız:</strong> Lütfen kullanıcı ve parola bilgilerinizi kontrol edip tekrar deneyiniz.</i>);
        else if (errorCode === "error")
            return (<i><strong>Giriş başarısız:</strong> Sunucu iletişim hatası. Lütfen internet bağlantınızı kontrol edip tekrar deneyiniz.</i>);
        else if (errorCode === "APIVersionMismatch")
            return (<i><strong>Giriş başarısız:</strong> Uygulama sürümünüz güncel değil. Lütfen mevcut uygulamayı daha yeni bir sürüme güncelleyiniz.</i>);
        else 
            return (<i><strong>Giriş başarısız:</strong> Sunucu iletişim hatası. Lütfen internet bağlantınızı kontrol edip tekrar deneyiniz.</i>);   
    }

    render(){
        if (this.props.errorCode === "") return (null);
        // only 1 error code for now
        return( 
            <div className="form-group text-left"> 
                <div className="row">
                    <div className="col col-sm-12">
                        {this.createErrorMessage(this.props.errorCode)}
                    </div>
                </div> 
            </div> 
        );
    }
}
