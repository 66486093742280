import React from "react";  
import memoryCache from '../common/memoryCache';
import statics from '../common/statics'; 
import moment from 'moment'; 
import networkServices from '../services/NetworkServicesBase'; 
import SubAccountList from '../Home/SubAccountList';

export default class Panel extends React.Component { 
    // type: None, Success, Error
    state = { 
        resultMessage : {message:'', type:'None'} ,
        form          : { password: "", partition:1 },
        partitions    : null,
        selecedPartition : 1,
        selectedAccountId: 0
    }
    componentDidMount(){  
        // get token from cache
        let token = memoryCache.get('token');

        // set account id to be rendered or list of subaccounts
        const selectedAccountId = (token.user_type === statics.AccountTypes.SuperUser) ? 0 : token.account_id;
        this.setState({selectedAccountId: selectedAccountId});

        if (selectedAccountId === 0) { return; }

        // prepare request parameters
        let requestBody = { accountId: selectedAccountId };
        let requestConfig = { noResponseExpected:false, async:true, token: token.access_token };
    
        // perform request
        networkServices.requestToServer('GET', statics.Endpoints.ENDPOINT_ACCOUNT_PARTITIONS, requestBody, requestConfig)
        .then(partitions => {  
            this.setState({partitions: partitions}); 
        }, (err)=>{   
            this.setState({resultMessage: {message: 'Partisyonlar alınırken hata oluştu...', type:'Error'}}); 
        });     
    }

    handleChangeInput = event => {
        const { value, maxLength } = event.target;
        const password = value.slice(0, maxLength);
    
        this.setState({
          form: {
            password
          }
        });
      };

    onArmClick = (e) => {
        e.preventDefault(); 
        const token = memoryCache.get('token'); 
        const password = this.refs.password.value;
        const partititon = this.state.selecedPartition;  
        const playerId =  memoryCache.get('playerId');   

        // if password is not empty or longer than 4 digits
        if (password === '' || password.length < 4) {
            this.setState({resultMessage: {message: 'Lütfen geçerli bir parola parola girip tekrar deneyiniz.', type:'Error'}});
            return;
        }

        if (!this.checkTriggerActionCount()) {
            return false;
        }
         
        // prepare request parameters
        const requestBody = { Accountid: this.state.selectedAccountId, Password: password, Partition: partititon, UserId: token.user_id, DeviceId:playerId};  
        const requestConfig = { noResponseExpected:false, async:true, token: token.access_token }; 

        networkServices.requestToServer('POST', statics.Endpoints.ENDPOINT_PANEL_ARM, requestBody, requestConfig).then(result => { 
            
            if(result === statics.PanelOperationStatus.Successfull) { 
                this.setState({resultMessage: {message: 'Panel kurulum isteği gönderildi.', type:'Success'}});
            }
            else if (result === statics.PanelOperationStatus.PhoneNotAvailable) {
                this.setState({resultMessage: {message: 'Panel tanımlamalarınızda eksiklik bulunmaktadır. Lütfen bu işlemi yapabilmek için öncelikle çağrı merkezimizi arayınız.', type:'Error'}}); 
            }
        }, (err)=>{
            this.setState({resultMessage: {message: 'Panel kurulumu yapılamadı. Tekrar deneyiniz.', type:'Error'}});  
        }); 
    }

    onDisarmClick = (e) => {
        e.preventDefault(); 
        const token = memoryCache.get('token');
        const password = this.refs.password.value;
        const partititon = this.state.selecedPartition;  
        const playerId =  memoryCache.get('playerId');   
        
        // if password is not empty or longer than 4 digits
        if (password === '' || password.length < 4) {
            this.setState({resultMessage: {message: 'Lütfen geçerli bir parola parola girip tekrar deneyiniz.', type:'Error'}});
            return;
        }

        if (!this.checkTriggerActionCount()) {
            return false;
        }
        
        // prepare request parameters
        const requestBody = { Accountid: this.state.selectedAccountId, Password: password, Partition: partititon, UserId: token.user_id, DeviceId:playerId}; 
        const requestConfig = { noResponseExpected:false, async:true, token: token.access_token }; 

        networkServices.requestToServer('POST', statics.Endpoints.ENDPOINT_PANEL_DISARM, requestBody, requestConfig).then(result => { 
            this.setState({resultMessage: {message: 'Panel kapatma isteği gönderildi.', type:'Success'}}); 
        }, (err)=>{
            this.setState({resultMessage: {message: 'Panel kapatma yapılamadı. Tekrar deneyiniz.', type:'Error'}});  
        }); 
    }
    onClearResultMessage = () => {
        this.setState({resultMessage: {message: '', type:'None'}}); 
    }

    onSubmitChangeHandler = (e) => { 
        e.preventDefault();        
    }

    onSelectedPartitionChange  = event => { 
        this.setState({ selecedPartition:event.target.value });
    }

    checkTriggerActionCount = () => {
        let panelAction = memoryCache.get('panelAction');
        if (!panelAction) {
            memoryCache.set('panelAction', this.createPanelAction(1));
        } else if (panelAction.count>3){
            let now = moment();
            let then = panelAction.lastModified;
            if (now.diff(then, 'seconds')<statics.PanelArmDisarmButtonTriggerInterval){
                this.setState({resultMessage: {message: 'Kısa süre içerisinde çok sayıda istek yaptınız. Lütfen az sonra tekrar deneyiniz.', type:'Error'}}); 
                return false;
            } else {
                memoryCache.set('panelAction', this.createPanelAction(1));
            }
        } else {            
            panelAction.count++;
            memoryCache.set('panelAction', this.createPanelAction(panelAction.count));
        }
        return true;
    }

    createPanelAction = (count) => {
        let now = moment();
        let action = {
            count:count,
            lastModified: now
        }
        return action; 
    }

    renderPartitions = () => { 
        const partitionList = this.state.partitions;

        if (partitionList == null || partitionList.length === 1) return null; 
        var itemKey = 0;
        return (
            <div className="form-group">
                <label className="form-label" htmlFor="partition-select">Partisyon</label>
                <select className="form-control" id="partition-select" defaultValue={this.state.selecedPartition}  onChange={this.onSelectedPartitionChange} >
                    {
                     partitionList.map( (partition) => { 
                        itemKey++;
                        return (
                            <option key={itemKey} value={partition.Id}>{partition.Name}</option> 
                        );
                     })
                    }
                </select>
            </div>

        )
        
     };

    onAccountSelectedHandler = (accountId) =>{
        this.setState({selectedAccountId:accountId});
    }
    render(){ 
        const resultMessage = this.state.resultMessage;
        const password = this.state.form.password; 
        const panelView = (
            <main id="js-page-content" role="main" className="page-content">
                <div className="subheader">
                    <h1 className="subheader-title">
                        <i className="subheader-icon fal fa-window"></i> AlarmPaneli
                        <small>
                            Alarm panelinizi uygulama üzerinden kurmak/kapatmak için parolanızı girip aşağıdaki kilit düğmesine basınız.
                        </small>
                    </h1>
                </div>
                
                <div id="panel-1" className="panel"> 
                    <div className="panel-container show">
                        <div className="panel-content"> 
                            <form onSubmit ={this.onSubmitChangeHandler}>
                            { 
                                this.renderPartitions()
                            }
                                <div className="form-group">
                                    <label className="form-label" htmlFor="password">Panel parolanız</label>
                                    <input type="number"  onChange={this.handleChangeInput} value={password} ref="password" id="password" maxLength="6" className="form-control panel-password" />
                                </div>  
                            </form>
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-grow-1 p-0"> 
                    <div className="d-flex flex-column flex-grow-1"> 
                        <div className="flex-grow-0">        
                            <ul className="app-list emergency-icon-list">
                                <li>
                                    <a href='# '  onClick={this.onArmClick} className="app-list-item hover-white">
                                        
                                        <span className='icon-stack'>
                                            <i className="base base-9 icon-stack-3x opacity-100 color-danger-500"></i> 
                                            <i className="base base-2 icon-stack-2x opacity-100 color-danger-300"></i> 
                                            <i className="fal fa-lock-alt icon-stack-1x opacity-100 color-white"></i>
                                        </span>   
                                        <span className="app-list-name">
                                            Paneli Kur
                                        </span>
                                    </a>
                                </li> 
                                <li>
                                    <a href='# '  onClick={this.onDisarmClick} className="app-list-item hover-white">
                                        <span className='icon-stack'>
                                            <i className="base base-9 icon-stack-3x opacity-100 color-success-500"></i> 
                                            <i className="base base-2 icon-stack-2x opacity-100 color-success-300"></i> 
                                            <i className="fal fa-lock-open icon-stack-1x opacity-100 color-white"></i>
                                        </span> 
                                        <span className="app-list-name">
                                            Paneli Kapat
                                        </span>
                                    </a>
                                </li> 
                            </ul> 
                        </div>
                    </div>
                </div>
           
                <ResultToast resultMessage={resultMessage} clearFunction={this.onClearResultMessage}></ResultToast> 
            </main>
            ); 
        const accountSelectionView = (<main id="js-page-content" role="main" className="page-content">
        <div className="subheader">
            <h1 className="subheader-title">
                <i className="subheader-icon fal fa-window"></i> Abone Seçimi
                <small>
                    Bağlı bir abonenin paneline ulaşmak için ilgili abone satırının üzerine tıklayın.
                </small>
            </h1>
        </div>
        
        <SubAccountList filterText='' onRowClickedHandler={this.onAccountSelectedHandler}/>
    </main>);
        const selectedAccountId = this.state.selectedAccountId;
        return (
             (selectedAccountId === 0) ? accountSelectionView: panelView
        );
        }
    }  

function ResultToast({resultMessage, clearFunction}) {   
    if (resultMessage.message === '') return (null); 
    // Clear all timeouts
    var id = window.setTimeout(function() {}, 0);

    while (id--) {
        window.clearTimeout(id); // will do nothing if no timeout with id is present
    }

    // Create timeout
    const timer = setTimeout(() => {clearFunction(); clearTimeout(timer)}, 2000); 
   
    const className = (resultMessage.type==='Success') ? "alert alert-info alert-dismissible fade show" : "alert alert-danger alert-dismissible fade show";

    return( 
        <div className={className} role="alert"> 
            {resultMessage.message} 
        </div> );
} 
