import React from "react"; 
import statics from '../common/statics';
import memoryCache from '../common/memoryCache';
import networkServices from '../services/NetworkServicesBase'; 

export default class Announcement extends React.Component {
    state = {
        announcements: []
    }

    componentDidMount(){ 
        // get token from cache
        let token = memoryCache.get('token');
 
        // prepare request parameters
        let requestBody = { userId: token.user_id };
        let requestConfig = { noResponseExpected:false, async:true, token: token.access_token };

        // perform request
        networkServices.requestToServer('GET', statics.Endpoints.ENDPOINT_ANNOUNCEMENTS_GET, requestBody, requestConfig)
        .then(announcements => {  
            this.setState({announcements: announcements}); 
        }, (err)=>{  
            this.setState({announcements: ['Veri yükleme sırasında hata oluştu..']});
        });  
            
    } 
    
    getAnnouncementRowItems = () => { 
        var itemKey = 0;
         return this.state.announcements.map( (item) => { 
             itemKey++;
           return (
             <p key={itemKey}><i className="fa-fw fal fa-comment"></i>{item.Message}</p>
         );
       });
     };

    render(){
        return( 
            <div className="alert alert-primary alert-dismissible fade show bm-0" role="alert">
                <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
                {this.getAnnouncementRowItems()} 
            </div>
        );
    }
}