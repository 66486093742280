import React from "react"; 
import statics from '../common/statics';
import memoryCache from '../common/memoryCache';
import networkServices from '../services/NetworkServicesBase';
import _ from 'underscore';  
import { withRouter } from "react-router";

class SubAccountList extends React.Component {
    state = {
        subAccounts: [],
        filterText:'',
        onRowClickedHandler:null
    }

    componentDidMount(){   

        let _responseHandlers = { successFunction : this.getSubAccountsSuccessfull, errorFunction: this.getSubAccountsError };
        this.getSubAccountList(_responseHandlers);
        this.setState({filterText: this.props.filterText});
        this.setState({onRowClickedHandler: this.props.onRowClickedHandler});
    } 

    
    static getDerivedStateFromProps(props, state) {
        // You don't have to do this check first, but it can help prevent an unneeded render
        if (props.filterText !== state.filterText) {
            return { filterText: props.filterText };
        } 
        if (props.onRowClickedHandler !== state.onRowClickedHandler) {
            return { onRowClickedHandler: props.filterText };
        } 
        
        return null;
     }

    getSubAccountList = (_responseHandlers) => {
        // get token from cache 
        let token = memoryCache.get('token');

        // prepare request parameters
        let requestBody = { userId: token.user_id, accountStatusId: statics.AccountStatus.Active };
        let requestConfig = { noResponseExpected:false, async:true, token: token.access_token };
        let responseStatus = false; 

        // perform request
        networkServices.requestToServer('GET', statics.Endpoints.ENDPOINT_ACCOUNT_SUBACCOUNTS, requestBody, requestConfig)
        .then(items => { 
            if (_responseHandlers.successFunction)
            {
                _responseHandlers.successFunction(items);
            }

            responseStatus = true;
        }, (err)=>{ 
            _responseHandlers.errorFunction(err);
        }); 

        return responseStatus; 
    }

    getSubAccountsSuccessfull = (items) => {
        
        let subAccounts = [];
        
        items.map( 
            (v,i) => subAccounts.push(
                {  accountId:v.AccountId, code:v.AccountCode, name:v.AccountName, status: v.AccountStatusText }
            ));
       
            this.setState({
                subAccounts: subAccounts }); 
    }

    getSubAccountsError = () => {
        
    } 

    getFilteredAccounts = (filterText) => { 
        var filteredList = _.filter(this.state.subAccounts,
            (obj) => obj["code"].toLowerCase().includes(filterText.toLowerCase()) ||
            obj["name"].toLowerCase().includes(filterText.toLowerCase()));
        return filteredList
    } 

    navigateToReportPage = (accountId) => {
        if (this.state.onRowClickedHandler) {
            this.state.onRowClickedHandler(accountId);
        }
        else {
            this.props.history.push(statics.NavigationPaths.ReportWeekly + '/' + accountId);
        }
    }

    render(){ 
        let accounts = this.getFilteredAccounts(this.props.filterText??'');

        return( 
            <div className="flex-wrap align-items-center flex-grow-1 position-relative bg-gray-50">
                <div className="position-absolute pos-top pos-bottom w-100 custom-scroll">
                    <div className="d-flex h-100 flex-column">
                        <ul id="js-events" className="notification notification-layout-2">
                            {/* Begin iterating alarm events to populate table */}
                            {accounts.map( (account, i)=>
                                <li key={i}> 
                                    <div className="d-flex align-items-center px-3 px-sm-4 px-lg-5 py-1 py-lg-0 height-4 height-mobile-auto" >  
                                        <div className="d-flex flex-row flex-wrap flex-1 align-items-stretch align-self-stretch order-2 order-lg-3">
                                            <div className="row w-100">
                                                {/*  eslint-disable-next-line */}
                                                <a href="# " onClick={(e)=>{e.preventDefault(); this.navigateToReportPage(account.accountId)}} className="name d-flex width-sm align-items-center pt-1 pb-0 py-lg-1 col-12 col-lg-auto">{account.code}</a>
                                                {/*  eslint-disable-next-line */}
                                                <a href="# " onClick={(e)=>{e.preventDefault(); this.navigateToReportPage(account.accountId)}} className="name d-flex align-items-center pt-0 pb-1 py-lg-1 flex-1 col-12 col-lg-auto">{account.name}</a>
                                            </div>
                                        </div>
                                        <div className="fs-sm text-muted ml-auto hide-on-hover-parent order-4 position-on-mobile-absolute pos-top pos-right mt-2 mr-3 mr-sm-4 mt-lg-0 mr-lg-0">
                                        {account.status} 
                                        </div>
                                    </div>
                                </li>
                            )}
                            {/* End of iterating alarm events to populate table */}
                        </ul> 
                    </div>
                </div>
            </div> 
        );
    }
}

export default withRouter(SubAccountList);