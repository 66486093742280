import React from "react";  
import statics from '../common/statics';
import memoryCache from '../common/memoryCache'; 
import networkServices from '../services/NetworkServicesBase';  
import osHelper from "../common/osHelper";

export default class ChangePassword extends React.Component {
    state = {
        errorMessage: ''
    };

    componentDidMount(){ 
         
    }

    validateInputFields = (params) => {        
        if (params.oldPassword === '' || params.newPassword === '' || params.reNewPassword === ''){
            this.setState({errorMessage :'Lütfen istenilen bütün bilgileri giriniz!'});
            return false;
        }
        else if (params.newPassword !== params.reNewPassword){  
            this.setState({errorMessage : 'Yeni parolalarınız birbiriyle aynı değil. Lütfen birbirinin aynı parolayı girdiğinizden emin olunuz.'});
            return false;
        }
        return true;
    }

    onPasswordChangeRequestSuccessfull = (result) => {
        if(result === statics.ChangeUserPasswordResult.Successfull) { 
            this.resetTextFields();
            this.setState({errorMessage:'Parolanız başarı ile değiştirilmiştir.'}); 
            return;
        }
        else if (result === statics.ChangeUserPasswordResult.FailedWrongPasswordFormat) {
            this.setState({errorMessage:'Lütfen en az 4 en çok 8 karakterden oluşan ve en az bir rakam ve harflerden oluşan bir parola belirtiniz.'});
        }else if (result === statics.ChangeUserPasswordResult.FailedOldPasswordDoesNotMatch) {
            this.setState({errorMessage:'Eski parolanızı hatalı girdiniz. Lütfen tekrar deneyiniz.'});
        }
        else{
            this.setState({errorMessage:'Parolanız değiştirilirken hata oluştu. Lütfen tekrar deneyiniz.'});  
        }
        this.resetTextFields();
    }

    resetTextFields = () =>{ 
        this.refs.oldPassword.value = '';
        this.refs.newPassword.value = '';
        this.refs.reNewPassword.value ='';   
    }

    onSubmitChangeHandler = (e) => { 
        e.preventDefault();        
        const token = memoryCache.get('token');
        
        let deviceId = 'Web';
        // TODO: read deviceid from cordova or set as "Web"
        if (false && osHelper.isCordova){
            // read device info
            const deviceInfo = memoryCache.get('deviceInfo');
            deviceId = deviceInfo.deviceid;
        } 
        const oldPassword = this.refs.oldPassword.value;
        const newPassword = this.refs.newPassword.value;
        const reNewPassword = this.refs.reNewPassword.value;

        if (!this.validateInputFields({oldPassword:oldPassword, newPassword:newPassword, reNewPassword: reNewPassword })){
            this.resetTextFields();
            return;
        }

        // prepare request parameters
        let requestBody = { UserId:token.user_id, DeviceId:deviceId, CurrentPassword:oldPassword, NewPassword:newPassword, RequestInformation:'' };
        let requestConfig = { noResponseExpected:false, async:true, token: token.access_token };
        
        this.setState({showLoading: true});
        // perform request
        networkServices.requestToServer('POST', statics.Endpoints.ENDPOINT_PASSWORD_CHANGE_POST, requestBody, requestConfig)
            .then(result => { 
                this.onPasswordChangeRequestSuccessfull(result);
            }, (err)=>{
                this.setState({errorMessage:'Parolanız değiştirilirken hata oluştu. Lütfen tekrar deneyiniz.'});  
            }); 
    }

    onCancelChangeHandler = (e) => {
        e.preventDefault();        
        this.props.history.push(statics.NavigationPaths.Settings);
    }

    render(){ 
        const errorMessage = this.state.errorMessage;
        return ( 
            <main id="js-page-content" role="main" className="page-content pb-0"> 
                <div className="row">
                    <div className="col-lg-12 col-xl-12 order-lg-1 order-xl-1"> 
                        <div className="card mb-g rounded-top">
                            <div className="row no-gutters row-grid">
                                <div className="col-12">
                                    <div className="d-flex flex-column align-items-center justify-content-center p-4"> 
                                        <div className="row">
                                            <div className="col-xl-12">
                                                <div id="panel-1" className="panel">
                                                    <div className="panel-hdr">
                                                        <h2>
                                                            Şifre Değiştir
                                                        </h2>
                                                    </div>
                                                    <div className="panel-container show">
                                                        <div className="panel-content">
                                                            <div className="panel-tag">
                                                                Şifrenizini değiştirmek için eski ve yeni şifreniz gereklidir.
                                                            </div>
                                                            <form>
                                                                <div className="form-group">
                                                                    <label className="form-label" htmlFor="old-password">Eski şifreniz</label>
                                                                    <input type="password"  ref="oldPassword" id="old-password" className="form-control" defaultValue="password" />
                                                                </div>
                                                                <div className="form-group">
                                                                    <label className="form-label" htmlFor="new-password">Yeni şifreniz</label>
                                                                    <input type="password"  ref="newPassword" id="new-password" className="form-control" defaultValue="password" />
                                                                </div>
                                                                <div className="form-group">
                                                                    <label className="form-label" htmlFor="re-new-password">Yeni şifreniz(tekrar)</label>
                                                                    <input type="password"  ref="reNewPassword" id="re-new-password" className="form-control" defaultValue="password" />
                                                                </div>
                                                                <PasswordChangeError errorMessage={errorMessage}/>
                                                                <div className="form-group">
                                                                    <div className="d-flex align-items-center">
                                
                                                                    <div className="pr-3">
                                                                        {/*  eslint-disable-next-line */}
                                                                        <a href="# " onClick={this.onSubmitChangeHandler} className="btn btn-primary">
                                                                        Kaydet
                                                                        </a>
                                                                    </div>
                                                                    <div className="pr-3">
                                                                        {/*  eslint-disable-next-line */}
                                                                        <a href="# " onClick={this.onCancelChangeHandler} className="btn btn-default">
                                                                        Vazgeç
                                                                        </a>
                                                                    </div>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </main> 
        )
    }
} 

class PasswordChangeError extends React.Component { 
    render(){
        const errorMessage =this.props.errorMessage; 
        if (errorMessage === "") return (null); 
        return( 
            <div className="form-group text-left"> 
                <div className="row">
                    <div className="col col-sm-12">
                        {errorMessage}
                    </div>
                </div> 
            </div> 
        );
    }
}