import statics from './statics';
import platform from 'platform';

/* Helper class to get the OS type */
var osHelper =  { 
    /**
     * getOSName function returns the name of the function
     * @return {string} os name
     */
    getOSName: function() {
        var osFamily = platform.os.family;
        switch(osFamily){
            case statics.osTypes.IOS:
                return statics.osTypes.IOS;
            case statics.osTypes.ANDROID:
                return statics.osTypes.ANDROID;
            default:
                return statics.osTypes.OTHER;
        }
    },

    isCordova: function(){
        return !!window.cordova;
    },

    isMobileApp: function(){
      //at splashScreen we need to know before cordova is loaded if the app is native or runs in a browser
      //cordova will use the FILE protocol instead of HTTP, because app was loaded locally
      var app = document.URL.indexOf( 'http://' ) === -1 && document.URL.indexOf( 'https://' ) === -1;
      return app;
    },

    /**
     * isMobileBrowser
     * @return {Boolean} returns true when running on mobile browser
     */
    isMobileBrowser: function(){
        return this.getOSName() !== statics.osTypes.OTHER;
    },

    /**
     * isWeb
     * @return {boolean} returns  true when running on web
     */
    isWeb: function(){
        return this.getOSName() === statics.osTypes.OTHER;
    },

    getBrowserName:function() { 
        if((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) !== -1 ) 
        {
            return 'Opera';
        }
        else if(navigator.userAgent.indexOf("Chrome") !== -1 )
        {
            return 'Chrome';
        }
        else if(navigator.userAgent.indexOf("Safari") !== -1)
        {
            return 'Safari';
        }
        else if(navigator.userAgent.indexOf("Firefox") !== -1 )  
        {
            return 'Firefox';
        }
        else if((navigator.userAgent.indexOf("MSIE") !== -1 ) || (!!document.documentMode === true ))
        {
            return 'IE'; 
        }  
    }


};

export default osHelper;
