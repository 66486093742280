import React from "react";  
import Header from "../components/Header.jsx";
import EmergencyModals from "../Emergency/EmergencyModals.jsx";
import LeftMenu from "../components/LeftMenu.jsx";  
import Footer from "../components/Footer.jsx"; 
import { CheckUserAuthtication } from '../services/AuthenticationService';  
import statics from '../common/statics'; 
import {redirectToHomePage} from '../common/utilities';

export default class PrivateLayout extends React.Component { 
    componentDidMount(){ 

        document.addEventListener("deviceready", this.onDeviceReady, false);
        window.initMyAppConfig();
        window.initiateDocumentReady();
     }

     
    onDeviceReady = () => {
        document.addEventListener("backbutton", function (e) { 
            e.preventDefault();
            const url = window.location.href;
            const isHomePage = url.match(/#\/home/g);
            const isLoginPage = url.match(/#\/login/g);
            if (isHomePage || isLoginPage){
                navigator.app.exitApp();
            } else{
                redirectToHomePage();
            }
            console.log('Private layout on device ready');
        }.bind(this), false );

        // Enable to debug issues.
        // window.plugins.OneSignal.setLogLevel({logLevel: 4, visualLevel: 4});

        var notificationOpenedCallback = function(jsonData) {
            // console.log('notificationOpenedCallback: ' + JSON.stringify(jsonData));
        };

        window.plugins.OneSignal
            .startInit("a0e79a70-6d8a-43fe-8dd2-07e6a0d0a70f")
            .handleNotificationOpened(notificationOpenedCallback)
            .endInit();
        
        // Call syncHashedEmail anywhere in your app if you have the user's email.
        // This improves the effectiveness of OneSignal's "best-time" notification scheduling feature.
        // window.plugins.OneSignal.syncHashedEmail(userEmail);
    } 

    render(){
        // guard for anonymous user. check for user authentication
        if (!CheckUserAuthtication()) { 
            window.location.replace(statics.NavigationPaths.Root); 
            return null;
        }
        return (   
                <div className="page-inner"> 
                    <LeftMenu />
                    <div className="page-content-wrapper"> 
                        <Header />
                        {this.props.children}
                        <div className="page-content-overlay" data-action="toggle" data-class="mobile-nav-on"></div> 
                        <EmergencyModals />
                        <Footer />
                    </div>
                </div> 
        )
    }
} 
