import React from 'react';   
import {
  HashRouter, Switch, 
  Route
} from "react-router-dom";
import Home from './Home/Home.jsx';  
import Report from './Report/Report.jsx';  
import Login from './Login.jsx';  
import PrivateLayout from './Layout/PrivateLayout';
import PublicLayout from './Layout/PublicLayout';
import './App.css';   
import Settings from './Settings/Settings.jsx';
import ChangePassword from './Settings/ChangePassword.jsx';
import NetworkError from './Error/NetworkError.jsx'; 
import Emergency from './Emergency/Emergency.jsx';
import Panel from './Panel/Panel.jsx';

function App() {  
  
// Custom route component
const AppRoute = ({ component: Component, layout: Layout, ...rest }) => (
  <Route {...rest} render={props => (
    <Layout>
      <Component {...props} />
    </Layout>
  )} />
)

  return ( 
    <HashRouter> 
       <Switch>
        <AppRoute exact path="/" layout={PublicLayout} component={Login} />
        <AppRoute exact path="/login" layout={PublicLayout} component={Login} />
        <AppRoute exact path="/report/:range?/:accountId?" layout={PrivateLayout} component={Report} />
        <AppRoute exact path="/home" layout={PrivateLayout} component={Home} /> 
        <AppRoute exact path="/settings" layout={PrivateLayout} component={Settings} /> 
        <AppRoute exact path="/pwdchange" layout={PrivateLayout} component={ChangePassword} /> 
        <AppRoute exact path="/networkerror" layout={PrivateLayout} component={NetworkError} /> 
        <AppRoute exact path="/emergency" layout={PrivateLayout} component={Emergency} /> 
        <AppRoute exact path="/panel" layout={PrivateLayout} component={Panel} /> 
        
      </Switch> 
    </HashRouter> 
  );
}

export default App;
