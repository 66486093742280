import React from "react"; 
import statics from '../common/statics';
import osHelper from '../common/osHelper';
import memoryCache from '../common/memoryCache';
import { withRouter} from 'react-router-dom';

class Header extends React.Component {
    state = {
        showPanicButtons : true,
        showIndividualReports : true
    }
    
    componentDidMount(){
        let token = memoryCache.get('token');
        var showPanicButtons = osHelper.isCordova();
        var showIndividualReports = token.user_type !== statics.AccountTypes.SuperUser;

        this.setState({showPanicButtons:showPanicButtons, showIndividualReports:showIndividualReports});
    }
 
    onHomePageClick = (e) =>{
        e.preventDefault();
        this.props.history.push(statics.NavigationPaths.Home);
    }
 
    onWeeklyReportClick = (e) => {
        e.preventDefault();
        this.props.history.push(statics.NavigationPaths.ReportWeekly);
    } 

    onSettingsClick = (e) => {
        e.preventDefault();
        this.props.history.push(statics.NavigationPaths.Settings);    
    }

    onEmergencyClick  = (e) => {
        e.preventDefault();
        this.props.history.push(statics.NavigationPaths.Emergency);    
    }

    onPanelClick = (e) => {
        e.preventDefault();
        this.props.history.push(statics.NavigationPaths.Panel);    
    }

    render() {
        return (
            <header className="page-header" role="banner">  
                <div className="hidden-lg-up">
                    <a href='# ' onClick={e => e.preventDefault()}  className="header-btn btn press-scale-down" data-action="toggle" data-class="mobile-nav-on">
                        <i className="ni ni-menu"></i>
                    </a>
                </div> 

                <div className="d-flex flex-row-reverse shortcut-icons-flexbox-outer">                
                    <div className="shortcut-icon-outer w50">
                        <a href='# ' onClick={this.onSettingsClick} className="header-icon shortcut-icon-link" title="Ayarlar">
                            <i className="fal fa-cog"></i>
                            <span>Ayarlar</span>
                        </a> 
                    </div>
                    
                    {!this.state.showPanicButtons?null:( 
                    <div className="shortcut-icon-outer">
                        <a href='# ' onClick={this.onEmergencyClick} className="header-icon shortcut-icon-link" title="Acil">
                            <i className="fal fa-exclamation-triangle"></i>
                            <span>&nbsp;Acil&nbsp;</span>
                        </a> 
                    </div>
                    )}      
                    
                    {!this.state.showPanicButtons?null:( 
                    <div className="shortcut-icon-outer">
                        <a href='# ' onClick={this.onPanelClick} className="header-icon shortcut-icon-link" title="Acil">
                            <i className="fal fa-lock"></i>
                            <span>Panel</span>
                        </a> 
                    </div> 
                    )}      
                    
                    {!this.state.showIndividualReports?null:(
                    <div className="shortcut-icon-outer">
                        <a href='# ' onClick={this.onWeeklyReportClick} className="header-icon shortcut-icon-link" title="Raporlar">
                            <i className="fal fa-cube"></i>
                            <span>Rapor</span>
                        </a> 
                    </div>
                    )}                 
                    <div className="shortcut-icon-outer w60">
                        <a href='# ' onClick={this.onHomePageClick} className="header-icon shortcut-icon-link" title="Anasayfa">
                            <i className="fal fa-home shortcut-icon-link-span ML15"></i>
                            <span>Anasayfa</span>
                        </a> 
                    </div>
                </div>
            </header>
        );
    }
}

export default withRouter(Header);