import React from "react";  
import Announcement from './Annoncement'; 
import ActivitiesSummary from './ActivitiesSummary';
import SubAccountList from './SubAccountList';
import statics from '../common/statics';
import memoryCache from '../common/memoryCache'; 

export default class Home extends React.Component {
    state = {
        renderMode: '' // summary or subaccounts
    }
    
    componentDidMount(){ 
        const token = memoryCache.get('token');
        // TODO: token check and  

        const renderMode = (token.user_type === statics.AccountTypes.SuperUser) ? 'subaccounts' : 'summary';
        this.setState({renderMode: renderMode});

        // initiate layout script and styles
        this.initiateLayout(); 
    }

    initiateLayout = () =>{
        //An array of assets
        let scripts = [ 
            { src: "./js/hamburger-menu-helper.js" }, 
        ]
        //Append the script element on each iteration
        scripts.map(item => { 
            const script = document.createElement("script");
            script.src = item.src;
            script.async = true;
            document.body.appendChild(script);
            return true;
        }); 

        document.body.className = "mod-bg-1 nav-function-minify layout-composed";
    }

    onSearchTextChanged = (event) => {        
        this.setState({filterText: event.target.value});
    }

    render(){ 
        return ( 
            <main id="js-page-content" role="main" className="page-content pb-0"> 
                <div className="d-flex flex-grow-1 p-0"> 
                    <div className="d-flex flex-column flex-grow-1"> 
                        <div className="flex-grow-0"> 
                            <Announcement />
                            <RenderListTitleByRenderMode renderMode = {this.state.renderMode} searchTextChangedHandler = {this.onSearchTextChanged}/>
                        </div> 
                        <RenderListByRenderMode renderMode = {this.state.renderMode} filterText={this.state.filterText} />
                    </div> 
                </div>
            </main> 
        )
    }
} 

function RenderListByRenderMode(props) {
    const renderMode = props.renderMode;
    const filterText = props.filterText;
    if (renderMode === 'summary') {
      return <ActivitiesSummary />;
    }
    else if (renderMode ==='subaccounts') {
        return <SubAccountList filterText={filterText} />
    }
    return null;
}

function RenderListTitleByRenderMode(props) {
    const renderMode = props.renderMode;
    const searchTextChangedHandler = props.searchTextChangedHandler;

    if (renderMode ==='summary')
    {
        return ( 
            <div className="panel-tag margin-bottom-0">
                Yakında gerçekleşen aktiviteler
            </div>
        );
    }
    else if (renderMode ==='subaccounts') {
        return (
            <div>
                <div className="d-flex align-items-center pl-1 pr-3 py-3 pl-sm-3 pr-sm-4 py-sm-4 px-lg-5 py-lg-4  border-faded border-top-0 border-left-0 border-right-0 flex-shrink-0">
                    <div className="d-flex position-relative ml-auto">
                        <i className="fas fa-search position-absolute pos-left fs-lg px-3 py-2 mt-1"></i>
                        <input type="text" className="form-control bg-subtlelight pl-6" placeholder="Abone ara" onChange={searchTextChangedHandler} />
                    </div>
                </div>
                <div className="panel-tag margin-bottom-0">
                    Bağlı bir abonenin aktivite listesine gitmek için satırın üzerine tıklayın.
                </div>
            </div>
        );
    }
    return null; 

}