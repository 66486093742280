import statics from './statics';
import osHelper from './osHelper';

var secureStorage = (function () {
    /**
     * Android secure storage wrapper based on https://github.com/edelworksgithub/SharedPreferences
     * contains getter and setter for android platform
     */
    var android = {
        /**
         * Init sharedpreferences file
         * @private
         */
        _initSharedPreferencesFile: function() {
            var successFn = function (response) {
                console.log('INIT SUCCESS '+ response);
            };
            var failFN = function (error) {
                console.log('INIT FAIL: ' + error);
            };
            /* jshint ignore:start */
            window.sharedpreferences.getSharedPreferences(statics.SharedPreferencesKey, 'MODE_PRIVATE', successFn, failFN);
            /* jshint ignore:end */
        },

        /**
         * shared preferences putString setter
         * @param {string} key
         * @param {string} value
         */
        set: function (key, value) {
            var successFn = function () {
                console.log('SET SUCCESS - Key: ' + key);
            };
            var failFN = function (error) {
                console.log('SET FAIL - Key: ' + key + ' Error: ' + error);
            };

            android._initSharedPreferencesFile();
            /* jshint ignore:start */
            window.sharedpreferences.putString(key, value, successFn, failFN);
            /* jshint ignore:end */
        },
        /**
         * shared preferences getString
         * @param {string} key
         */
        get: function (key,callBackGetFunction) {
            var successFN = function (value) {
                if(key === 'access_token' || key === 'renew_token') {
                    console.log('GET SUCCESS - Key: ' + key);
                } else {
                    console.log('GET SUCCESS - Key: ' + key + ' Value: ' + value);
                }
                callBackGetFunction(value);
            };
            var failFN = function (error) {
                console.log('GET FAIL - Key: ' + key + ' Error: ' + error);
                callBackGetFunction();
            };
            android._initSharedPreferencesFile();
            /* jshint ignore:start */
            window.sharedpreferences.getString(key, successFN, failFN);
            /* jshint ignore:end */
        },
        /**
         * shared preferences remove
         * @param key
         */
        remove: function (key) {
            var successFN = function () {
                console.log('REMOVE SUCCESS - Key: ' + key);
            };
            var failFN = function (error) {
                console.log('REMOVE FAIL - Key: ' + key + ' Error: ' + error);
            };
            android._initSharedPreferencesFile();
            /* jshint ignore:start */
            window.sharedpreferences.remove(key, successFN, failFN);
            /* jshint ignore:end */
        }
    };
    /**
     * iOS secure storage wrapper based on https://github.com/shazron/KeychainPlugin
     * contains getter and setter for iOS platform
     */
    var iOS = {
        /**
         * @property {string} internal iOS namespace
         */
        serviceName: "Keychain", //statics.SharedPreferencesKey,
        /**
         * iOS keychain setter
         * @param {string} key
         * @param {string} value
         */
        set: function (key, value) {
            var kc = window.Keychain;//A.C: I could't test via Iphone. It should be read with window.(CordovaPlugin)

            console.log('ios set operation hit');
            console.log('kc is:' + (kc?"filled":"empty"));

            var successFN = function () {
                console.log('SET SUCCESS - Key: ' + key);
            };
            var failFN = function (error) {
                console.log('SET FAIL - Key: ' + key + ' Error: ' + error);
            };
            //keychain plugin only supports string values
            if (typeof value === 'string'){
              kc.set(successFN, failFN, key, value, iOS.serviceName);
            }
        },
        
        /**
         * iOS keychain getter
         * @param {string} key
         */
        get: function (key,callBackGetFunction) {
            var kc = window.Keychain;//A.C: I could't test via Iphone. It should be read with window.(CordovaPlugin)

            console.log('ios get operation hit');
            console.log('kc is:' + (kc?"filled":"empty"));

            var successFN = function (value) {
                console.log('GET SUCCESS - Key: ' + key + ' Value: ' + value);
                callBackGetFunction(value);
            };
            var failFN = function (error) {
                console.log('GET FAIL - Key: ' + key + ' Error: ' + error);
                callBackGetFunction();
            };
            kc.get(successFN, failFN, key, iOS.serviceName);
        },
        /**
         * iOS keychain remove
         * @param {string} key
         */
        remove: function (key) {
            var kc = window.Keychain;//A.C: I could't test via Iphone. It should be read with window.(CordovaPlugin)

            var successFN = function () {
                console.log('REMOVE SUCCESS - Key: ' + key);
            };
            var failFN = function (error) {
                console.log('REMOVE FAIL - Key: ' + key + ' Error: ' + error);
            };
            kc.remove(successFN, failFN, key);
        }

    };

    /**
     * Public methods
     */
    return {
        /**
         * Get a record from secure storage
         * @param {string} key
         */
        get: function (key ,callBackGetFunction) {
            if(!!window.cordova){ //check if cordova is working or not
                switch (osHelper.getOSName()) {
                    case statics.osTypes.ANDROID:
                        android.get(key, callBackGetFunction);
                        break;
                    case statics.osTypes.IOS:
                        iOS.get(key, callBackGetFunction);
                        break;
                    default:
                        break;
                }
            }
        },
        /**
         * Set or update a record into the secure storage
         * @param {string} key
         * @param {string} value
         */
        set: function (key, value) {
            if(!!window.cordova){
                switch (osHelper.getOSName()) {
                    case statics.osTypes.ANDROID:
                        android.set(key, value);
                        break;
                    case statics.osTypes.IOS:
                        iOS.set(key, value);
                        break;
                    default:
                        break;
                }
            }
        },

        /**
         * remove record into the secure storage
         * @param {string} key
         */
        remove: function (key) {
            if(!!window.cordova){
                switch (osHelper.getOSName()) {
                    case statics.osTypes.ANDROID:
                        android.remove(key);
                        break;
                    case statics.osTypes.IOS:
                        iOS.remove(key);
                        break;
                    default:
                        break;
                }
            }
        },
    };
})();

export default secureStorage;
