import React from "react"; 
import {Logout} from '../services/AuthenticationService';
import statics from '../common/statics';
import { withRouter} from 'react-router-dom';
import osHelper from '../common/osHelper';
import memoryCache from '../common/memoryCache';
import {redirectToRoot} from '../common/utilities';


export class LeftMenu extends React.Component {  

    state = {
        showPanicButtons : true,
        showIndividualReports : true
    }

    componentDidMount(){
        let token = memoryCache.get('token');
        var showPanicButtons = osHelper.isCordova();
        var showIndividualReports = token.user_type !== statics.AccountTypes.SuperUser;

        this.setState({showPanicButtons:showPanicButtons, showIndividualReports:showIndividualReports});
    }
 
    onHomePageClick = (e) =>{
        e.preventDefault();
        this.props.history.push(statics.NavigationPaths.Home);
    }

    onWeeklyReportClick = (e) => {
        e.preventDefault();
        this.props.history.push(statics.NavigationPaths.ReportWeekly);
    }

    onMonthlyReportClick= (e) => {
        e.preventDefault();
        this.props.history.push(statics.NavigationPaths.ReportMonthly);
    }

    onQuarterlyReportClick= (e) => {
        e.preventDefault();
        this.props.history.push(statics.NavigationPaths.ReportQuarterly);
    }

    onSettingsClick = (e) => {
        e.preventDefault();
        this.props.history.push(statics.NavigationPaths.Settings);    
    }

    onLogoutClick = (e)=>{ 
        e.preventDefault();
        // perform any operation related with logout such as cache clear etc.
        Logout();
        // navigate to login page 
         redirectToRoot();
    }

    render(){    
        return (
            <aside className="page-sidebar">
            <div className="page-logo">
                <a href="/home" className="page-logo-link press-scale-down d-flex align-items-center position-relative" data-toggle="modal" data-target="#modal-shortcut">
                    <img src="./img/igmlogo.png" alt="İstanbul Güvenlik Merkezi" aria-roledescription="logo" />   
                </a>
            </div> 
            <nav id="js-primary-nav" className="primary-nav" role="navigation">
                <ul id="js-nav-menu" className="nav-menu">
                    <li>
                        {/*  eslint-disable-next-line */}
                        <a href="#" onClick={this.onHomePageClick} title="Anasayfa" data-filter-tags="homepage">
                            <i className="fal fa-home"></i>
                            <span className="nav-link-text" data-i18n="nav.homepage">Anasayfa</span>
                        </a>
                    </li>
                    {!this.state.showIndividualReports?null:(
                    <li>
                        {/*  eslint-disable-next-line */}
                        <a href='#' onClick={e => e.preventDefault()} title="Rapor" data-filter-tags="report">
                            <i className="fal fa-info-circle"></i>
                            <span className="nav-link-text" data-i18n="nav.report">Raporlar</span>
                        </a>
                        <ul>
                            <li>
                                {/*  eslint-disable-next-line */}
                                <a href="#" onClick={this.onWeeklyReportClick} title="Haftalık Aktivite Raporu" data-filter-tags="weekly report">
                                    <span className="nav-link-text" data-i18n="nav.report_weekly">Haftalık Rapor</span>
                                </a>
                            </li>
                            <li>
                                {/*  eslint-disable-next-line */}
                                <a href="#" onClick={this.onMonthlyReportClick} title="Aylık Rapor" data-filter-tags="monthly report">
                                    <span className="nav-link-text" data-i18n="nav.report_monthly">Aylık Rapor</span>
                                </a>
                            </li> 
                            <li>
                                {/*  eslint-disable-next-line */}
                                <a href="#" onClick={this.onQuarterlyReportClick} title="Privacy" data-filter-tags="3 Aylık Rapor">
                                    <span className="nav-link-text" data-i18n="nav.report_quarterly">3 Aylık Rapor</span>
                                </a>
                            </li> 
                        </ul>
                    </li>
                    )}
                    {!this.state.showPanicButtons?null:(
                    <li>
                        {/*  eslint-disable-next-line */}
                        <a href="#" onClick={e => e.preventDefault()} title="Acil Durum" data-filter-tags="emergency">
                            <i className="fal fa-exclamation-triangle"></i>
                            <span className="nav-link-text" data-i18n="nav.emergency">Acil Durum</span>
                        </a>
                        <ul>
                            <li>
                                {/*  eslint-disable-next-line */}
                                <a href="#" onClick={e => e.preventDefault()}  title="Polis Çağır" data-toggle="modal" data-target="#police-modal-alert">
                                    <span className="nav-link-text" data-i18n="nav.emergency_police">Polis Çağır</span>
                                </a> 
                            </li>
                            <li>
                                {/*  eslint-disable-next-line */}
                                <a href="#" onClick={e => e.preventDefault()}  title="Ambulans Çağır" data-toggle="modal" data-target="#ambulance-modal-alert">
                                    <span className="nav-link-text" data-i18n="nav.emergency_ambulance">Ambulans Çağır</span>
                                </a>  
                            </li>
                            <li>
                                {/*  eslint-disable-next-line */}
                                <a href="#" onClick={e => e.preventDefault()}  title="İtfaiye Çağır" data-toggle="modal" data-target="#fire-modal-alert">
                                    <span className="nav-link-text" data-i18n="nav.emergency_fire">İtfaiye Çağır</span>
                                </a>
                            </li>
                        </ul>
                    </li> 
                    )}
                    <li>
                        {/*  eslint-disable-next-line */}
                        <a href="# " onClick={this.onSettingsClick} title="Ayarlar" data-filter-tags="settings">
                            <i className="fal fa-cog"></i>
                            <span className="nav-link-text" data-i18n="nav.settings">Ayarlar</span>
                        </a>
                    </li>
                    <li>
                        {/*  eslint-disable-next-line */}
                        <a href="#" onClick={this.onLogoutClick} title="Güvenli Çıkış" data-filter-tags="logout">
                            <i className="fal fa-tag"></i>
                            <span className="nav-link-text" data-i18n="nav.logout">Çıkış</span>
                        </a>
                    </li>
                </ul>
                <div className="filter-message js-filter-message bg-success-600"></div>
            </nav> 
            </aside> 
        ); 
    }

}
export default withRouter(LeftMenu);
