import React from "react"; 

export default class Emergency extends React.Component { 
    state = { 
    }
    componentDidMount(){         
    }
   
    render(){
        return ( 
            <main id="js-page-content" role="main" className="page-content">
                <div className="d-flex flex-grow-1 p-0"> 
                    <div className="d-flex flex-column flex-grow-1"> 
                        <div className="flex-grow-0">        
                            <ul className="app-list emergency-icon-list">
                                <li>
                                    <a href='# '  data-toggle="modal" data-target="#police-modal-alert" className="app-list-item hover-white">
                                        
                                        <span className='icon-stack'>
                                            <i className="base base-9 icon-stack-3x opacity-100 color-info-500"></i> 
                                            <i className="base base-2 icon-stack-2x opacity-100 color-info-300"></i> 
                                            <i className="fal fa-car icon-stack-1x opacity-100 color-white"></i>
                                        </span>   
                                        <span className="app-list-name">
                                            Polis
                                        </span>
                                    </a>
                                </li>
                                <li> 
                                    <a href='# ' data-toggle="modal" data-target="#ambulance-modal-alert" className="app-list-item hover-white">
                                        <span className='icon-stack'>
                                            <i className="base base-4 icon-stack-3x opacity-100 color-warning-500"></i> 
                                            <i className="base base-4 icon-stack-2x opacity-100 color-warning-300"></i> 
                                            <i className="fal fa-ambulance icon-stack-1x opacity-100 color-white"></i>
                                        </span>
                                        <span className="app-list-name">
                                            Ambulans
                                        </span>
                                    </a>
                                </li>
                                <li>
                                    <a href='# ' data-toggle="modal" data-target="#fire-modal-alert" className="app-list-item hover-white">
                                        <span className='icon-stack'>
                                            <i className="base base-9 icon-stack-3x opacity-100 color-danger-500"></i> 
                                            <i className="base base-2 icon-stack-2x opacity-100 color-danger-300"></i> 
                                            <i className="fal fa-fire-extinguisher icon-stack-1x opacity-100 color-white"></i>
                                        </span> 
                                        <span className="app-list-name">
                                            İtfaiye
                                        </span>
                                    </a>
                                </li> 
                            </ul> 
                        </div>
                    </div>
                </div>
            </main>
            )
        }
    } 
    