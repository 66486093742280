import React from "react"; 
import statics from '../common/statics';
import memoryCache from '../common/memoryCache';
import networkServices from '../services/NetworkServicesBase';


export default class EmergencyModals extends React.Component {  
    
    triggerEmergencyRequest = (_responseHandlers, options) => {
        // get token from cache 
        let token = memoryCache.get('token');

        // prepare request parameters
        let requestBody = {  ButtonType: options.buttonType, DeviceId: options.deviceId, RequestInformation: options.comment, UserId: token.user_id  };
        let requestConfig = { noResponseExpected:false, async:true, token: token.access_token };
        let responseStatus = false; 

        // perform request
        networkServices.requestToServer('POST', statics.Endpoints.ENDPOINT_TRIGGER_PANICBUTTON_POST, requestBody, requestConfig)
        .then(() => { 
            if (_responseHandlers &&_responseHandlers.successFunction)
            {
                _responseHandlers.successFunction();
            }

            responseStatus = true;
        }, (err)=>{ 
            if (_responseHandlers && _responseHandlers.errorFunction){ 
                _responseHandlers.errorFunction(err);
            } 
        }); 

        return responseStatus; 
    }

    onRequestPoliceClick = () =>{
        let options = {
            buttonType: statics.PanicButtonTypes.Police, 
            deviceId: 'test', 
            comment: 'Polis'
        }
        
        this.triggerEmergencyRequest (null, options);
    }

    onRequestAmbulanceClick = () => {
        let options = {
            buttonType: statics.PanicButtonTypes.Ambulance, 
            deviceId: 'test', 
            comment: 'Ambulans'
        }
        
        this.triggerEmergencyRequest (null, options);        
    }
    
    onRequestFireDepartmentClick = () =>{
        let options = {
            buttonType: statics.PanicButtonTypes.Fire, 
            deviceId: 'test', 
            comment: 'İtfaiye'
        }
        
        this.triggerEmergencyRequest (null, options);        
    }

    render(){
        return ( 
            <div className="emergency_modals">

                <div className="modal modal-alert fade" id="police-modal-alert" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Panik Durumu</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true"><i className="fal fa-times"></i></span>
                                </button>
                            </div>
                            <div className="modal-body">
                                Polis çağırmak istediğinizden emin misiniz?
                            </div>
                            <div className="modal-footer">
                                <button type="button" ref="btnPoliceModalNo" className="btn btn-secondary" data-dismiss="modal">Hayır</button>
                                <button type="button" onClick={this.onRequestPoliceClick} className="btn btn-primary" data-dismiss="modal">Evet</button>
                            </div>
                        </div>
                    </div>
                </div>  
                
                <div className="modal modal-alert fade" id="ambulance-modal-alert" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Panik Durumu</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true"><i className="fal fa-times"></i></span>
                                </button>
                            </div>
                            <div className="modal-body">
                                Ambulans çağırmak istediğinizden emin misiniz?
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Hayır</button>
                                <button type="button" onClick={this.onRequestAmbulanceClick}  data-dismiss="modal" className="btn btn-primary">Evet</button>
                            </div>
                        </div>
                    </div>
                </div> 
                
                <div className="modal modal-alert fade" id="fire-modal-alert" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Panik Durumu</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true"><i className="fal fa-times"></i></span>
                                </button>
                            </div>
                            <div className="modal-body">
                                İtfaiye çağırmak istediğinizden emin misiniz?
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Hayır</button>
                                <button type="button" onClick={this.onRequestFireDepartmentClick}  data-dismiss="modal" className="btn btn-primary">Evet</button>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
        )
    }
}
