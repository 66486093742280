import statics from '../common/statics';
import secureStorage from '../common/secureStorage';
import networkServices from './NetworkServicesBase';   
import cookie from 'js-cookie';
import memoryCache from '../common/memoryCache';
import {PostLogoutLog} from '../services/LoggingService';
import osHelper from '../common/osHelper';
 
/* authenticate method to perform authentication over API
    * @param  {credentials}  consist of {username, password, rememberme} 
    * @param  {_authenticationHandlers} consist of function references for handling of {successFunction, failFunction}
    * @return {promise}
    */
function authenticate(credentials, _authenticationHandlers) {

    // prepare request data
    let responseBody = {   grant_type: statics.Credentials.grantType, 
        tenantId: statics.Credentials.tenantId, 
        username: credentials.username, 
        password: credentials.password };
    let requestConfig = { noResponseExpected:false, async:false };
    let responseStatus = false; 

    // perform request
    networkServices.requestToServer('POST', statics.Endpoints.ENDPOINT_TOKEN, responseBody, requestConfig)
        .then(token => { 
            if (_authenticationHandlers.successFunction)
            {
                _authenticationHandlers.successFunction(
                    {
                        token: token,
                        credentials: credentials
                    });
            }

            responseStatus = true;
        }, (err)=>{ 
            _authenticationHandlers.errorFunction(err);
        });
    
        return responseStatus;
}

function rememberUserCredentials(credentials){
    
    var formCookie = { username: credentials.username, password: credentials.password };

    if(!!window.cordova){ //check if cordova is working or not
        var value = JSON.stringify(formCookie);
        secureStorage.set(statics.Cookie.Key, value);
    } else {
        cookie.set(statics.Cookie.Key, formCookie,{ expires: statics.Cookie.ExpiresIn });                
    } 
}

function forgetUserCredentials(){
    if(!!window.cordova){ //check if cordova is working or not
        secureStorage.remove(statics.Cookie.Key);
    } else {
        cookie.remove(statics.Cookie.Key);
    }
}


function APIVersionCompatible(_handlers) {
    // get current major API version
    const currentVersionMajor = statics.VersionNumber.split(".")[0]; 
    // prepare request data
    let responseBody = {  apkVersionMajor: currentVersionMajor };
    let requestConfig = { noResponseExpected:false, async:false };

    // perform request
    networkServices.requestToServer('POST', statics.Endpoints.ENDPOINT_CHECK_API_VERSION_COMPATIBILITY, responseBody, requestConfig)
        .then(compatible => { 
            if (_handlers.successFunction)
            {
                _handlers.successFunction(compatible);
            }
        }, (err)=>{ 
            _handlers.errorFunction(err);
        });
}

function Logout(){ 
    // push logout information 
    if (osHelper.isCordova()){
        PostLogoutLog();
    } 
    
    // perform anything related with logout operation.
   memoryCache.clear();
}

function CheckUserAuthtication(){
    const token = memoryCache.get('token');
    let userIsAuthenticated = (token !== null);

    if (!userIsAuthenticated){
        memoryCache.clear();
    }
    return userIsAuthenticated;
}

export {
    authenticate,
    rememberUserCredentials,
    forgetUserCredentials,
    APIVersionCompatible,
    CheckUserAuthtication,
    Logout
}