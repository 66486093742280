var statics = {
    Endpoints: {
        BASE_URL:  'https://webapi.istgm.com', //,'http://localhost:55992', // 'https://webapitest.istgm.com',
        ENDPOINT_TOKEN: 'token',
        ENDPOINT_ORDER_LIST: 'api/Orders/List',
        ENDPOINT_ACTIVITIES_SUMMARY: 'api/Activities/Summary',
        ENDPOINT_ACTIVITIES_GET: 'api/Activities/Get',
        ENDPOINT_SERVICES_SUMMARY: 'api/Services/Summary',
        ENDPOINT_ACCOUNT_SUBACCOUNTS: 'api/Accounts/SubAccounts',
        ENDPOINT_ACCOUNT_SUBACCOUNTDETAIL: 'api/Accounts/SubAccountDetail',
        ENDPOINT_ACCOUNT_ACCOUNTINFO: 'api/Accounts/AccountInfo',        
        ENDPOINT_ACCOUNT_PARTITIONS: 'api/Accounts/GetAccountPartitions',
        ENDPOINT_API_VERSION:'api/Versions/GetAPIVersion',     
        ENDPOINT_CHECK_API_VERSION_COMPATIBILITY:'api/Versions/CheckAPIVersionCompatibility' ,
        ENDPOINT_LOGINLOG_POST:'api/Session/LogUserLogin',
        ENDPOINT_LOGOUTLOG_POST:'api/Session/LogUserLogout',
        ENDPOINT_ANNOUNCEMENTS_GET:'api/Announcements/Announcements',
        ENDPOINT_TRIGGER_PANICBUTTON_POST:'api/PanicButton/Trigger',
        ENDPOINT_NOTIFICATIONS_GET:'api/Notification/GetNotificationStatus',
        ENDPOINT_NOTIFICATIONS_SET:'api/Notification/SetNotifications',
        ENDPOINT_PASSWORD_CHANGE_POST:'api/Session/ChangeUserCredential',
        ENDPOINT_PANEL_ARM:'api/Panel/Arm',
        ENDPOINT_PANEL_DISARM:'api/Panel/Disarm',
    },
    
    NavigationPaths: {
        Root: '/',
        LoginPage: '/login',
        Home: '/home',
        Report: '/report',
        ReportWeekly: '/report/weekly',
        ReportMonthly: '/report/monthly',
        ReportQuarterly: '/report/quarterly',
        Settings: '/settings',
        PasswordChange: '/pwdchange',
        GenericNetworkError:'/networkerror',
        Emergency:'/emergency',
        Panel:'/panel'
    },
    
    Cookie: { 
        Key: 'IGMCookie',
        ExpiresIn: 30*10
    },

    Credentials: {
        grantType: 'password',
        tenantId: 'igm' // 'igm' //igm or acarkent
    },
    /* Operating System Types */
    osTypes: {
        'IOS': 'iOS',
        'ANDROID': 'Android',
        'OTHER': 'other'
    },

    AccountTypes: {
        User: '1',
        SuperUser: '2'
    },

    AccountStatus: {
        Deleted: '-1',
        Passive: '0',
        Active: '1',
        Reserved: '2',
        InProgress: '3',
        Canceled: '4',
        Blocked: '5'
    },

    
    PanicButtonTypes: {
        Ambulance: 1,
        Fire: 2,
        Police: 3
    },

    ChangeUserPasswordResult:
    {
        Successfull:0,
        FailedUnknown:1,
        FailedUserDoesNotExist:2,
        FailedOldPasswordDoesNotMatch:3,
        FailedOldPasswordSameWithNew:4,
        FailedWrongPasswordFormat:5
    },
    
    PanelOperationStatus:
    {
        Successfull:0,
        PhoneNotAvailable:1,
        InvalidPassword:2,
        GenericError:3
    },

    PanelArmDisarmButtonTriggerInterval: 30, //30 seconds
    
    AutoRefreshInterval: 30000, // 60 seconds

    VersionNumber: '0.9',
    SharedPreferencesKey: 'IGMSharedPreferences',
}
export default statics;