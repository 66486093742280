import React from "react";  
import {Logout} from '../services/AuthenticationService'; 
import {redirectToRoot} from '../common/utilities';

export default class NetworkError extends React.Component {
    componentDidMount(){ 
    }

    doLogout = (e) =>{
        e.preventDefault(); 
         // perform any operation related with logout such as cache clear etc.
         Logout(); 
         redirectToRoot();
    }
    render(){ 
        return ( 
            <main id="js-page-content" role="main" className="page-content">
                <div className="h-alt-hf d-flex flex-column align-items-center justify-content-center text-center">
                    <div className="alert alert-danger bg-white pt-4 pr-5 pb-3 pl-5" id="demo-alert">
                        <h1 className="fs-xxl fw-700 color-fusion-500 d-flex align-items-center justify-content-center m-0">
                            <img className="profile-image-sm rounded-circle bg-danger-700 mr-1 p-1" src="img/logo-minify.png" alt="SmartAdmin WebApp Logo" />
                            <span className="color-danger-700">> Sunucu Bağlantı Hatası.</span>
                        </h1>
                        <h3 className="fw-500 mb-0 mt-3">
                            Sunucudan veri alınamadı.
                        </h3>
                        <p></p>
                        <p className="container container-sm mb-0 mt-1 ">
                            Oluşan bağlantı hatası nedeniyle sizi yeniden giriş sayfasına yönlendiriyoruz.Siz de bu arada bağlantı ayarlarınızı kontrol edip internete bağlanabildiğinizden emin olunuz. 
                                                </p>
                        <div className="mt-4"> 
                            {/*  eslint-disable-next-line */}
                            <a href="# " onClick={this.doLogout} className="btn btn-primary">
                                <span className="fw-700">Tamam</span>
                            </a>
                        </div>
                    </div>
                </div>
            </main>
        )
    }
} 