import React from "react"; 
import statics from '../common/statics';
import memoryCache from '../common/memoryCache';
import networkServices from '../services/NetworkServicesBase';  
import osHelper from '../common/osHelper'; 
import moment from 'moment'; 
import localization from 'moment/locale/tr';
import {formatEventDate} from '../common/utilities';

export default class ActivitiesSummary extends React.Component {
    state = {
        alarmEvents: [],
        showLoading: true
    }

    componentDidMount(){ 
        this.renderRecentActivities();
    } 

    getRecentActivities = (_responseHandlers) => {
        // get token from cache 
        const token = memoryCache.get('token');

        this.setState({showLoading: true});

        // prepare request parameters
        let requestBody = { accountId: token.account_id };
        let requestConfig = { noResponseExpected:false, async:true, token: token.access_token };
        let responseStatus = false; 

        // perform request
        networkServices.requestToServer('GET', statics.Endpoints.ENDPOINT_ACTIVITIES_SUMMARY, requestBody, requestConfig)
        .then(items => { 
            if (_responseHandlers.successFunction)
            {
                this.setState({showLoading: false});
                _responseHandlers.successFunction(items);
            }

            responseStatus = true;
        }, (err)=>{  
            this.setState({showLoading: false});
            _responseHandlers.errorFunction(err);
        }); 

        return responseStatus; 
    }

    getRecentActivitiesSuccessfull = (items) => {
        
        let alarmEvents = [];
        
        moment.updateLocale('tr', localization) //For Turkey 
        
        items.map( 
            (v,i) => alarmEvents.push(
                {   name:v.AccountName, eventDescription: v.AlarmDescription, 
                    date: formatEventDate(v.EventTime),
                    code:v.AccountCode, pn:v.PartitionNo, dayName:v.EventDayName,
                    fcolor: v.ForegroundColor, bgcolor: v.BackgroundColor }
            ));
       
            this.setState({
                alarmEvents: alarmEvents }); 
    }

    getRecentActivitiesError = () =>{
        
    } 

    renderRecentActivities = () => {  
        let _responseHandlers = { successFunction : this.getRecentActivitiesSuccessfull, errorFunction: this.getRecentActivitiesError };
        this.getRecentActivities(_responseHandlers);
    }

    render(){ 
        let isCordova = osHelper.isCordova();
        return( 
            <div className="flex-wrap align-items-center flex-grow-1 position-relative bg-gray-50">
                {(this.state.showLoading) ? 
                <RenderLoadingAnimation/> 
                : (
                    <table className="table table-bordered">
                        <thead>
                            {isCordova? (
                            <tr>
                                <th className="colDate">Tarih</th>
                                <th className="colEventText"> <i className="fa fa-comment-o"></i> Aciklama</th> 
                            </tr> 
                        ): (                          
                            <tr> 
                                <th className="colDay"> <i className="fa fa-bell-o"></i> Gün</th>
                                <th className="colDate"> <i className="fa fa-calendar-o"></i> Tarih</th> 
                                <th className="colEventCode"> <i className="fa fa-bell-o"></i> Kod</th>
                                <th className="colEventPN"> PN</th>
                                <th className="colEventText"> <i className="fa fa-comment-o"></i> Aciklama</th> 
                            </tr> 
                    )}
                        </thead>
                        <tbody> 
                            {this.state.alarmEvents.map( (event, i)=>
                                    <RenderAlarmActivity Event={event} key = {i} isCordova={isCordova} />
                                )}                      
                        </tbody>
                        </table>  
                // <div className="position-absolute pos-top pos-bottom w-100 custom-scroll">
                //     <div className="d-flex h-100 flex-column">
                //         <ul id="js-events" className="notification notification-layout-2">
                //             {/* Begin iterating alarm events to populate table */}
                //             {this.state.alarmEvents.map( (event, i)=>
                //                 <RenderAlarmActivity Event={event} key = {i} />
                //             )}
                //             {/* End of iterating alarm events to populate table */}
                //         </ul> 
                //     </div>
                // </div>
                )}
            </div> 
        );
    }
}

function RenderLoadingAnimation(){
    return( 
        <div className="spinner-border spinner-border-sm" role="status">
            <span className="sr-only">Yükleniyor...</span>
        </div>
    )
}

function RenderAlarmActivity(props){
    const event = props.Event; 
    const isCordova = props.isCordova;
    let eventStyle = {
        backgroundColor: '#' + event.bgcolor,
        color: '#' +event.fcolor 
      }; 
    if (isCordova) { 
        return (                                
            <tr style={eventStyle} key={event.Id}>
                <td className="tdWordWrap">{event.date}</td>
                <td className="tdWordWrap">{event.eventDescription}</td>
            </tr>
        );
    } 
    return (                                
        <tr style={eventStyle} key={event.Id}> 
            <td className="tdWordWrap">{event.dayName}</td>            
            <td className="tdWordWrap">{event.date}</td>
            <td className="tdWordWrap">{event.code}</td>
            <td className="tdWordWrap">{event.pn}</td>
            <td className="tdWordWrap">{event.eventDescription}</td>  
        </tr>
    );  
}