/**
 * Generic cache to be used by all data that needs to be store in mem cache
 */

/**
 * @module Memory Cache
 * @description The domainModelCache Singleton class.
 */
// eslint-disable-next-line
var memoryCache = module.exports = {
    items: {},
    /**
     * Get item from the cache
     * @param  {string} key item key from the cache
     * @return {object}     matched item / undifined if not matched
     */
    get: function(key) {
        return  JSON.parse(window.sessionStorage.getItem(key)); 
    },
    /**
     * Add an item from the cache
     * @param {string} key   the key to add
     * @param {object} value value to be associated to the key
     */
    set: function(key, value) {
        window.sessionStorage.setItem(key, JSON.stringify(value)); 
    },
    /**
     * Remove an item from the cache
     * @param  {string} key the key for which to delete the entry
     */
    remove: function(key) {
        window.sessionStorage.removeItem(key);
    },    
    /**
     * Clear the whole cache
     */
    clear: function() {
        sessionStorage.clear();
    }
};