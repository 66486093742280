import React from "react";  

export default class PublicLayout extends React.Component { 
    
    componentDidMount(){
        document.addEventListener("deviceready", this.onDeviceReady, false);
    }


    onDeviceReady = () => {
    } 

    render(){
        return (   
            <div className="page-inner bg-brand-gradient">
                {this.props.children} 
            </div> 
        )
    }
} 
