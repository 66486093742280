import React from "react";
import statics from '../common/statics';
import memoryCache from '../common/memoryCache';
import networkServices from '../services/NetworkServicesBase'; 
import osHelper from '../common/osHelper'; 
import moment from 'moment'; 
import localization from 'moment/locale/tr';
import {formatEventDate} from '../common/utilities';

export default class Report extends React.Component { 
    state = {
        alarmEvents: [],
        filterText:'',
        showLoading: true
    }

    componentDidMount(){        
        document.body.className = "mod-bg-1 nav-function-minify layout-composed"; 
        
        this.initActivityRendering(this.props.match.params.range); 
        this.initAutoRefresh();
        
    }

    initAutoRefresh() { 
        const intervalId = setInterval(() => { 
            this.initActivityRendering(this.props.match.params.range);  
        }, statics.AutoRefreshInterval); 
        
        this.setState({intervalId: intervalId});
    }

    componentWillUnmount() {
       // use intervalId from the state to clear the interval
       clearInterval(this.state.intervalId);
       // console.log('interval cleared');
    }

    initActivityRendering = (reportRange) => {
        // get token from cache 
        const token = memoryCache.get('token');
        const accountId = this.props.match.params.accountId??token.account_id;

        // redirect to homepage if no accountid is available
        if (!accountId){
            this.props.history.push(statics.NavigationPaths.Home);
        }

        let startDate;
        let endDate = moment();
        
        if (reportRange === 'weekly'){ 
            startDate = moment().subtract(1, 'weeks');
        }
        else if (reportRange === 'monthly'){ 
            startDate = moment().subtract(1, 'months');
        }
        else if (reportRange === 'quarterly'){ 
            startDate = moment().subtract(3, 'months');            
        } 

        let options = { accountId: accountId, startDate: startDate, endDate: endDate, token: token.access_token}

        let _responseHandlers = { successFunction : this.getActivitiesSuccessfull, errorFunction: this.getActivitiesError };
        this.getRecentActivities(_responseHandlers, options);
    }

    getRecentActivities = (_responseHandlers, options) => {
        
        // prepare request parameters
        let requestBody = { accountId: options.accountId, startDate: options.startDate.format("YYYY-MM-DD"), endDate: options.endDate.format("YYYY-MM-DD") };
        let requestConfig = { noResponseExpected:false, async:true, token: options.token };
        let responseStatus = false; 
        
        this.setState({showLoading: true});
        // perform request
        networkServices.requestToServer('GET', statics.Endpoints.ENDPOINT_ACTIVITIES_GET, requestBody, requestConfig)
        .then(items => { 
            if (_responseHandlers.successFunction)
            {
                this.setState({showLoading: false});
                _responseHandlers.successFunction(items);
            }

            responseStatus = true;
        }, (err)=>{ 
            this.setState({showLoading: false});
            _responseHandlers.errorFunction(err);
        }); 

        return responseStatus; 
    }

    getActivitiesSuccessfull = (items) => {
        
        let alarmEvents = [];
        
        moment.updateLocale('tr', localization) //For Turkey
         
        items.map( 
            (v,i) => alarmEvents.push(
                {   name:v.AccountName, eventDescription: v.AlarmDescription, 
                    date: formatEventDate(v.EventTime),
                    code:v.AccountCode, pn:v.PartitionNo, dayName:v.EventDayName,
                    fcolor: v.ForegroundColor, bgcolor: v.BackgroundColor }
            ));
       
        this.setState({alarmEvents: alarmEvents }); 
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.match.params.range !== this.props.match.params.range) {
          const range = nextProps.match.params.range;
          this.initActivityRendering(range); 
        }
      }

    getActivitiesError = (err) => {
        // network errors handled as generic errors. 
        // others not responded in error case but returned within success state. Hence just logging is required here
    }

    onWeeklyClick = (e) => { 
        e.preventDefault();        
        this.props.history.push(statics.NavigationPaths.ReportWeekly);
    }
    
    onMonthlyClick = (e) => {
        e.preventDefault();
        this.props.history.push(statics.NavigationPaths.ReportMonthly); 
    }

    onQuarterlyClick = (e) => {
        e.preventDefault();
        this.props.history.push(statics.NavigationPaths.ReportQuarterly);
    }
    
    render(){
        let isCordova = osHelper.isCordova();
        return ( 
            <main id="js-page-content" role="main" className="page-content"> 
                <div className="d-flex flex-grow-1 p-0"> 
                    <div className="d-flex flex-column flex-grow-1 bg-white"> 
                        <div className="flex-grow-0"> 
                            <div className="d-flex align-items-center pl-1 pr-3 py-3 pl-sm-3 pr-sm-4 py-sm-4 px-lg-5 py-lg-4  border-faded border-top-0 border-left-0 border-right-0 flex-shrink-0">
                                
                                <div className="d-flex align-items-left">
                                    <div className="pr-3 pl-1">
                                        {/*  eslint-disable-next-line */}
                                        <a href="# " onClick={this.onWeeklyClick} className="btn btn-primary">
                                            Haftalık
                                        </a>
                                    </div>
                                    <div className="pr-3">
                                        {/*  eslint-disable-next-line */}
                                        <a href="# " onClick={this.onMonthlyClick} className="btn btn-primary">
                                            Aylık
                                        </a>
                                    </div>
                                    <div className="d-flex position-relative ml-auto">
                                        {/*  eslint-disable-next-line */}
                                        <a href="# " onClick={this.onQuarterlyClick} className="btn btn-primary">
                                        3 Aylık
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div> 
                        <div className="flex-wrap align-items-center flex-grow-1 position-relative bg-gray-50">
                            {(this.state.showLoading) ? 
                                <RenderLoadingAnimation/> 
                            : (
                                <table className="table table-bordered">
                                    <thead>
                                        {isCordova? (
                                        <tr>
                                            <th className="colDate">Tarih</th>
                                            <th className="colEventText"> <i className="fa fa-comment-o"></i> Aciklama</th> 
                                        </tr> 
                                    ): (                          
                                        <tr> 
                                            <th className="colDay"> <i className="fa fa-bell-o"></i> Gün</th>
                                            <th className="colDate"> <i className="fa fa-calendar-o"></i> Tarih</th> 
                                            <th className="colEventCode"> <i className="fa fa-bell-o"></i> Kod</th>
                                            <th className="colEventPN"> PN</th>
                                            <th className="colEventText"> <i className="fa fa-comment-o"></i> Aciklama</th> 
                                        </tr> 
                                )}
                                    </thead>
                                    <tbody> 
                                        {this.state.alarmEvents.map( (event, i)=>
                                             <RenderAlarmActivity Event={event} key = {i} isCordova={isCordova} />
                                          )}                      
                                    </tbody>
                                    </table>  
                            )}
                        </div> 
                    </div>
                </div>
            </main> 
        )
    }
} 

function RenderLoadingAnimation(){ 
    return( 
        <div className="spinner-border spinner-border-sm pl-2 pt-2" role="status">
            <span className="sr-only">Yükleniyor...</span>
        </div>
    )
}

function RenderAlarmActivity(props){
    const event = props.Event; 
    const isCordova = props.isCordova;
    let eventStyle = {
        backgroundColor: '#' + event.bgcolor,
        color: '#' +event.fcolor
      }; 
    
      if (isCordova) { 
          return (                                
              <tr style={eventStyle} key={event.Id}>
                  <td className="tdWordWrap">{event.date}</td>
                  <td className="tdWordWrap">{event.eventDescription}</td>
              </tr>
          );
    } 
    return (                                
        <tr style={eventStyle} key={event.Id}> 
            <td className="tdWordWrap">{event.dayName}</td>            
            <td className="tdWordWrap">{event.date}</td>
            <td className="tdWordWrap">{event.code}</td>
            <td className="tdWordWrap">{event.pn}</td>
            <td className="tdWordWrap">{event.eventDescription}</td>  
        </tr>
    );  
} 