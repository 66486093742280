import statics from '../common/statics'; 
import networkServices from './NetworkServicesBase'; 
import memoryCache from '../common/memoryCache';


function PostLoginLog(body) {

    const token = memoryCache.get('token');

    // prepare request data
    const responseBody = body; 
    let requestConfig = { noResponseExpected:false, async:true, token: token.access_token }; 
 
    // perform request
    networkServices.requestToServer('POST', statics.Endpoints.ENDPOINT_LOGINLOG_POST, responseBody, requestConfig);
}

function PostLogoutLog() {

    const token = memoryCache.get('token');
    // save player id
    const playerId = memoryCache.get('playerId');  

    // prepare request data
    const responseBody = { DeviceId: playerId, UserId: token.user_id }; 
    let requestConfig = { noResponseExpected:false, async:true, token: token.access_token }; 
 
    // perform request
    networkServices.requestToServer('POST', statics.Endpoints.ENDPOINT_LOGOUTLOG_POST, responseBody, requestConfig);
}


export {
    PostLoginLog,
    PostLogoutLog
}