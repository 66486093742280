import React from "react"; 

export default function Footer() {
    return ( 
       <footer className="page-footer" role="contentinfo"> 
       <div>
           <ul className="list-table m-0">
               <li className="pl-3"><span>2020 © İstanbul Güvenlik Merkezi</span></li> 
               <li className="pl-3"><span>{process.env.REACT_APP_VERSION}</span></li>
           </ul>
       </div>
   </footer> 
    )}
