import statics from '../common/statics';   
import $ from 'jquery';
import {redirectToNetworkErrorPage} from '../common/utilities';
 
/* An api to provide some fake calls */
const NetworkServicesBase = { 

    /* requestServerBase generic function which handles the authentication and makes a request (GET, POST, PUT, DELETE) to the server.
     * @param  {string} the type of the request (GET, POST, PUT, DELETE).
     * @param  {string} the path which is requested.
     * @param  {array} the data which is passed in the request (used only for POST and PUT).
     * @param  {boolean} noResponseExpected -marks if the response body will be set or not. This is necessary because ajax will try to parse the response if it is JSON.
     * @param  {string} the base api url.
     * @param  {boolean} async              -async call flag
     * @return {promise}
     */
    requestToServer: function(requestType, requestEndpoint, requestParams, options) {
        var dfd = $.Deferred();
        if (!options.token && (requestEndpoint !== statics.Endpoints.ENDPOINT_TOKEN) && 
                      (requestEndpoint !== statics.Endpoints.ENDPOINT_API_VERSION &&
                      (requestEndpoint !== statics.Endpoints.ENDPOINT_CHECK_API_VERSION_COMPATIBILITY))) {
            // log here : token is empty 
        } else {
            this.requestToServerBase(dfd, requestType, requestEndpoint, requestParams, options);
        }
        return dfd.promise();
    },

    requestToServerBase: function(dfd, requestType, requestEndpoint, requestParams, options) { 
        var requestObject = {
            requestType: requestType, 
            path: requestEndpoint,
            params: requestParams,
            autheticationKey: 'Bearer',
            autheticationValue: options.token,
            noResponseExpected: options.noResponseExpected
        };
        if (options.async !== undefined) {
            requestObject.async = options.async;
        }

        this.apiRequest(requestObject).then(function(successData) {
                return dfd.resolve(successData);
            },
            function(xhr, textStatus, errorThrown, requestBody) {
                return dfd.reject(xhr, textStatus, errorThrown);
            });
    },

    apiRequest: function(requestObject) {
        var dfd = $.Deferred(); 
        var requestBody = {
            type: requestObject.requestType,
            url: statics.Endpoints.BASE_URL + '/' + requestObject.path,
            data: requestObject.params,
            timeout: requestObject.timeout,
            cache: false,
            crossDomain: true,
            beforeSend: function(xhr) {
                if (requestObject.autheticationValue) {
                    xhr.setRequestHeader('Authorization', requestObject.autheticationKey + ' ' + requestObject.autheticationValue);
                }
            },
            success: function(response) {
                return dfd.resolve(response);
            },
            error: function(xhr, textStatus, errorThrown) {
                if (xhr.responseText === '' && xhr.status === 200 && textStatus === 'parsererror') {
                    return;
                }
                if (xhr.status === 0 && textStatus === 'error'){ // internal server error

                    redirectToNetworkErrorPage();
                } 
                return dfd.reject(xhr, textStatus, errorThrown);
            }
        };
        if (requestObject.async !== undefined) {
            requestBody.async = requestObject.async;
        }
        if (requestObject.noResponseExpected) {
            requestBody.dataType = 'text';
        } else {
            if (requestObject.requestType === 'GET') {
                requestBody.dataType = 'json';
            }
        }

        $.ajax(requestBody);
        return dfd.promise();
    },

    /**
     * Base for GET request
     */
    base_get_request: function(baseurl, endpoint, token) {
        var dfd = $.Deferred();
        var request = {
            method: 'GET',
            crossDomain: true,
            beforeSend: function(xhr) {
                if (token) {
                    xhr.setRequestHeader('Authorization', 'Bearer '  + token);
                }
            }
        };
        let errorHandler = (err) => {
            console.log(err);
        }

        $.ajax(baseurl + '/' + endpoint, request).then(function(data) {
            return dfd.resolve(data); // handle error case as well!!!
        }, errorHandler);

        return dfd.promise();
    },

    /**
     * Base for POST request
     */
    base_post_request: function(baseurl, endpoint, data, token) {
        var dfd = $.Deferred();
        var request = {
            method: 'POST',
            crossDomain: true,
            data: data,
            beforeSend: function(xhr) {
                if (token) {
                    xhr.setRequestHeader('Authorization', 'Bearer ' + token);
                }
            }
        };

        $.ajax(baseurl + '/' + endpoint, request).then(function(data) {
            return dfd.resolve(data); // handle error case as well!!!
        });
        return dfd.promise();
    },

    base_put_request: function(baseurl, endpoint, data) {
        var dfd = $.Deferred();
        $.ajax(baseurl + '/' + endpoint + '/' + data.id, {
            method: 'PUT',
            crossDomain: true,
            data: data
        }).then(function(data) {
            return dfd.resolve(data); // handle error case as well!!!
        });
        return dfd.promise();
    },

    base_delete_request: function(baseurl, endpoint, id) {
        var dfd = $.Deferred();
        $.ajax(baseurl + '/' + endpoint + '/' + id, {
            crossDomain: true,
            method: 'DELETE'
        }).then(function(data) {
            return dfd.resolve(data); // handle error case as well!!!
        });
        return dfd.promise();
    }
}


export default NetworkServicesBase;