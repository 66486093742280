import React from "react";  
import statics from '../common/statics';
import memoryCache from '../common/memoryCache'; 
import networkServices from '../services/NetworkServicesBase';  

export default class Settings extends React.Component {
    state = {
        accountDetails: {} 
    }
    
    componentDidMount(){ 
        let token = memoryCache.get('token'); 
        let accountId = token.account_id;
        
        if (accountId===""){
            this.setState({accountDetails: {}});
        } else {
            this.requestAccountDetails(token, accountId);
        }
    }


    requestAccountDetails = (token, accountId) => {
        // prepare request parameters
        let requestBody = { accountId: accountId };
        let requestConfig = { noResponseExpected:false, async:true, token: token.access_token };

        this.setState({showLoading: true});
        // perform request
        networkServices.requestToServer('GET', statics.Endpoints.ENDPOINT_ACCOUNT_SUBACCOUNTDETAIL, requestBody, requestConfig)
        .then(response => {  
            this.setState({accountDetails: response}); 

        }, (err)=>{ 
            this.setState({accountDetails: {}}); 
        }); 
    }

    onChangePasswordClicked = (e) => {
        e.preventDefault(); 
        this.props.history.push(statics.NavigationPaths.PasswordChange);
    }

    render(){ 
        const accountDetails = this.state.accountDetails;
        const hideAccountDetails = accountDetails.AccountCode===undefined;
        return ( 
            <main id="js-page-content" role="main" className="page-content pb-0"> 
                <div className="row">
                    <div className="col-lg-12 col-xl-12 order-lg-1 order-xl-1"> 
                        <div className="card mb-g rounded-top">
                            <div className="row no-gutters row-grid">
                                {hideAccountDetails?null:(
                                <div className="col-12">
                                    <div className="d-flex flex-column align-items-center justify-content-center p-4">
                                        <img src="img/demo/avatars/avatar-m.png" className="rounded-circle shadow-2 img-thumbnail" alt="" />
                                        <h5 className="mb-0 fw-700 text-center mt-3">
                                            {accountDetails.AccountCode} {accountDetails.AccountName} 
                                            <small className="text-muted mb-0">{accountDetails.Address ? (accountDetails.Address):null}</small>
                                        </h5>
                                    </div>
                                </div>
                                 )}
                                {hideAccountDetails?null:(
                                <div className="col-6">
                                    <div className="text-center py-3">
                                        <h5 className="mb-0 fw-700"> 
                                            {accountDetails.AccountStatusText}
                                            <small className="text-muted mb-0">Hesap durumu</small>
                                        </h5>
                                    </div>
                                </div>
                                 )}
                                {hideAccountDetails?null:(
                                <div className="col-6">
                                    <div className="text-center py-3">
                                        <h5 className="mb-0 fw-700">
                                            {accountDetails.ContactName ? (accountDetails.ContactName):null}
                                            <small className="text-muted mb-0">{accountDetails.ContactName ? ("Kontak kişisi"):null}</small>
                                        </h5>
                                    </div>
                                </div>
                                 )}
                                <div className="col-12">
                                    <div className="p-3 text-center">
                                        <span className="text-primary d-inline-block mx-3">&#9679;</span>
                                        {/*  eslint-disable-next-line */}
                                        <a href="# " onClick={this.onChangePasswordClicked}  className="btn-link font-weight-bold">Şifre Değiştir</a>                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </main> 
        )
    }
} 